import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class ScrollDecorator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const { scrollLimit } = this.props;
    const { node } = this;
    const { top } = node.getBoundingClientRect();
    this.setState(() => ({ locked: top + node.offsetTop < scrollLimit }));
  };

  render() {
    const { locked } = this.state;
    const { children, lockedClass, unlockedClass } = this.props;
    return (
      <span
        ref={node => {
          this.node = node;
        }}
        className={classNames(unlockedClass, { [lockedClass]: locked })}
      >
        {children}
      </span>
    );
  }
}

ScrollDecorator.propTypes = {
  children: PropTypes.node.isRequired,
  lockedClass: PropTypes.string.isRequired,
  unlockedClass: PropTypes.string.isRequired,
  scrollLimit: PropTypes.number.isRequired
};

export default ScrollDecorator;
