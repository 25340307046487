import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import classNames from "classnames";
import SEO from "../../components/SEO";
import IntroHeader from "../../components/IntroHeader";
import Faqs from "../../components/Faqs";
import { Container, Section } from "../../components/LayoutUtils";
import ScrollDecorator from "../../components/ScollDecorator";
import Layout from "../../layouts/index";
import getPageImage from "../../utils/getPageImage";
import Shop from "../../../static/shop.svg";
import Watermark from "../../../static/shop-watermark.svg";
import styles from "./styles.module.scss";

const FaqsPage = ({ data }) => (
  <Layout>
    <SEO
      image={getPageImage(data.contentfulPage)}
      title="Frequently Asked Questions"
      description="Bespoke tailoring can seem very complicated, here's some questions we hear frequently (and our answers)."
    />
    <IntroHeader {...data.contentfulPage} />
    <ScrollDecorator
      scrollLimit={560}
      unlockedClass={styles.shop}
      lockedClass={styles.shopLocked}
    >
      <Container>
        <Section>
          <div className="row">
            <div className={classNames(styles.watermark, "d-md-none")}>
              <img src={Watermark} alt="MacAngus & Wainwright" />
            </div>
            <div className="col-sm-12 col-lg-8">
              <Faqs />
            </div>
            <div className="col-sm-4 d-none d-lg-block">
              <img src={Shop} alt="MacAngus & Wainwright" />
            </div>
          </div>
        </Section>
      </Container>
    </ScrollDecorator>
  </Layout>
);

FaqsPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  {
    contentfulPage(slug: { eq: "faqs" }) {
      ...page
    }
  }
`;

export default FaqsPage;
