import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { get, map, pipe } from "lodash/fp";

import Faqs from "./Faqs";

const getFaqs = pipe(
  get("allContentfulFaq.edges"),
  map("node")
);

const query = graphql`
  {
    allContentfulFaq(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          id
          question
          answer {
            answer
          }
        }
      }
    }
  }
`;

const PriceListContainer = props => (
  <StaticQuery
    query={query}
    render={data => <Faqs faqs={getFaqs(data)} {...props} />}
  />
);

export default PriceListContainer;
