import React from "react";
import PropTypes from "prop-types";
import Markdown from "../Markdown";
import { H3 } from "../Type";
import styles from "./style.module.scss";

const Faqs = ({ faqs }) => (
  <div>
    {faqs.map(faq => (
      <div className={styles.faq} key={faq.id}>
        <H3>{faq.question}</H3>
        <Markdown source={faq.answer.answer} />
      </div>
    ))}
  </div>
);

Faqs.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      question: PropTypes.string,
      answer: PropTypes.shape({
        answer: PropTypes.string
      })
    })
  ).isRequired
};

export default Faqs;
